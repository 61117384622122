export const namefindSerpLink = (domain: string, utmCampaign: string) => {
  return (
    `https://www.godaddy.com/domainsearch/find?` +
    `key=parkweb&utm_source=godaddy&utm_medium=parkedpages&utm_campaign=${utmCampaign}&` +
    `tmskey=dpp_dbs&domainToCheck=${domain}&itc=namefind_redirect`
  );
};

export const isNamefind = (cid: string) => {
  return cid === "7dbc6047-87b6-4724-9c84-1e076f5b7c3d";
};
