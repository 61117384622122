import { useEffect } from "react";
import { LanderConfigAction, useLanderConfig } from "../commons/ConfigContext";
import postEvent, { createBaseLanderEvent } from "../commons/LanderEvent";
import { Settings } from "../config";
import { getIsConsentRequired } from "../consentManager/helper";
import { namefindSerpLink } from "../commons/namefindHelpers";
import { EventType } from "../commons/Constants";

const expKey = "parking_cp_3423";

// PARKING-3423 experiment
export function useConsentSerpRedirect() {
  const isConsentRequired = getIsConsentRequired();
  const { landerConfig, landerConfigDispatch } = useLanderConfig();
  const { domain, experiment } = landerConfig;

  const isExperiment = isConsentRequired && experiment?.key === expKey;

  // Update Channel
  useEffect(() => {
    if (!isExperiment) {
      return;
    }

    landerConfigDispatch({
      type: LanderConfigAction.UPDATE_CHANNEL_BY_TREATMENT,
      payload: experiment?.cohort === "on",
    });
  }, [isExperiment, landerConfigDispatch, experiment?.cohort]);

  // Redirect if useSerpWhenConsentRequired
  useEffect(() => {
    if (!isExperiment || !experiment?.data?.useSerpWhenConsentRequired) {
      return;
    }

    const serpLink = namefindSerpLink(
      domain?.rootDomain,
      experiment?.data?.utmCampaign
    );
    postEvent(Settings.EVENT_PUBLISH_API, {
      ...createBaseLanderEvent(EventType.SERP_REDIRECT_CONSENT, landerConfig),
      serpLink,
    });
    window.location.assign(serpLink);
  }, [
    domain?.rootDomain,
    experiment?.data?.useSerpWhenConsentRequired,
    experiment?.data?.utmCampaign,
    isExperiment,
    landerConfig,
  ]);
}
