import { useEffect } from "react";
import { LanderConfigAction, useLanderConfig } from "../commons/ConfigContext";
import { BannerType } from "../banner/Banner";
import { experimentIds } from "../commons/experiment-helpers";
import { useAdsenseContext } from "./AdsenseContext";

// PARKING-3696 experiment
export function useUpdateAuctionsBanner() {
  const { landerConfig, landerConfigDispatch } = useLanderConfig();
  const { hasAdsense } = useAdsenseContext();
  const { auctionsBannerExperimentId } = experimentIds;
  const { experiment } = landerConfig;

  const isExperiment =
    landerConfig?.lander?.banner?.type === BannerType.AUCTION &&
    experiment?.key === auctionsBannerExperimentId &&
    hasAdsense;

  useEffect(() => {
    if (!isExperiment) return;

    landerConfigDispatch({
      type: LanderConfigAction.UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT,
      payload: {
        inTreatmentCohort: experiment?.cohort === "pw_treatment",
        hasAdsense,
      },
    });
  }, [isExperiment, landerConfigDispatch, hasAdsense, experiment?.cohort]);
}
